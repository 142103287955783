.experience-item-company {
  display: flex;
  align-items: center;
}

.experience-item-position {
  max-width: 95%;
}


